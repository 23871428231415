//判断页面显示什么语言
var langArr=["/zh-CN/","/zh-TW/","/cs/","/da/","/nl/","/fi/","/fr/","/de/","/el/","/it/","/ja/","/no/","/pl/","/pt/","/ru/","/es/","/sv/","/hu/","/tr/"];
if(getCookie('lang')){
    var lang=getCookie('lang');
    localStorage.setItem('lang',lang)
}
if(getQueryVariable('lang')){
    var lang=getQueryVariable('lang');
    if(lang=='null'){
        lang='en'
    }
    localStorage.setItem('lang',lang)
}else{
    try{
        if(localStorage.getItem('lang')&&!getQueryVariable('source')){
            var lang=localStorage.getItem('lang');
        }else{
            var lang='en';
            for(var i=0;i<langArr.length;i++){
                if(location.href.indexOf(langArr[i])>-1){
                    lang=langArr[i].split('/')[1];
                    break;
                }else{
                    lang='en';
                }
            };
            localStorage.setItem('lang',lang);
        };
    }catch(e){
        var lang='en';
        for(var i=0;i<langArr.length;i++){
            if(location.href.indexOf(langArr[i])>-1){
                lang=langArr[i].split('/')[1];
                break;
            }else{
                lang='en';
            }
        };
        error(i18n[lang].no_cookie)
        console.log(e);
    }
}
$(function(){
    if(lang!='en'){
        // 链接修改
        for(var i=0;i<$('a').length;i++){
            if(String($('a').eq(i).attr('href')).indexOf('https://www.apeaksoft.com/')>-1){
                var url='https://www.apeaksoft.com/'+lang+'/'+$('a').eq(i).attr('href').split('https://www.apeaksoft.com/')[1];
                $('a').eq(i).attr('href',url);
            }
        };
        for(var i=0;i<$('[i18n]').length;i++){
            var name=$('[i18n]').eq(i).attr('i18n');
            $('[i18n]').eq(i).html(i18n[lang][name]);
        }
        for(var i=0;i<$('[i18n_input]').length;i++){
            var name=$('[i18n_input]').eq(i).attr('i18n_input');
            $('[i18n_input]').eq(i).attr('placeholder',i18n[lang][name])
        }
        for(var i=0;i<$('[i18n_title]').length;i++){
            var name=$('[i18n_title]').eq(i).attr('i18n_title');
            $('[i18n_title]').eq(i).attr('title',i18n[lang][name])
        }
        // 地址
        for(var i=0;i<$('.country_code option').length;i++){
            $('.country_code option').eq(i).html(i18n[lang]['country_'+[i+1]])
        }
    }
    $('.i18n_bg').hide();
});
//邮箱正则判断
let reg_email = /^[a-zA-Z0-9.!#$%&#$%&amp; '*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
let reg_password = /.{8,}$/;
let reg_code = /^[0-9]{6,6}$/;
function error_fn(id,that){
    switch(id){
        case 20001:
            error(i18n[lang].error_20001);
            if(location.origin.indexOf('account.')>-1){
                setTimeout(function(){
                    location.href="/login";
                },1000);
            }
            return false;
            break;
        case 20002:
            error(i18n[lang].error_20002);
            if(location.origin.indexOf('account.')>-1){
                setTimeout(function(){
                    location.href="/login";
                },1000);
            }
            return false;
            break;
        case 20003:
            error(i18n[lang].error_20003);
            if(location.origin.indexOf('account.')>-1){
                setTimeout(function(){
                    location.href="/login";
                },1000);
            }
            return false;
            break;
        case 20004:
            error(i18n[lang].error_20004);
            return false;
            break;
        case 20005:
            setCookie("user","",-1)
            if(location.origin.indexOf('account.')>-1){
                location.href="/login";
            }
            return false;
            break;
        case 20006:
            error(i18n[lang].error_20006);
            return false;
            break;
        case 20007:
            error(i18n[lang].error_20007);
            if(location.origin.indexOf('account.')>-1){
                setTimeout(function(){
                    location.href="/login";
                },1000);
            }
            return false;
            break;
        case 20008:
            error(i18n[lang].error_20008);
            if(location.origin.indexOf('account.')>-1){
                setTimeout(function(){
                    location.href="/login";
                },1000);
            }
            return false;
            break;
        case 20009:
            error(i18n[lang].error_20009);
            if(location.origin.indexOf('account.')>-1){
                setTimeout(function(){
                    location.href="/login";
                },1000);
            }
            return false;
            break;
    }
    return true;
}
// 地址栏判断操作
if(getQueryVariable('operation')){
    sessionStorage.setItem('operation','close');
}
if(getQueryVariable('3rdGoogle')=='error'){
    error(i18n[lang].error_22298);
}
// 请求接口地址
let domain=".apeaksoft.com";
let websiet="https://www.apeaksoft.com";
let post_url;
let google_client_id;
var user;
if(location.hostname=="www.apeaksoft.com" ||
location.hostname=="account.apeaksoft.com" ||
location.hostname=="imypass.apeaksoft.com" 
){
    post_url="https://account.api.apeaksoft.com/v9/";
    google_client_id="870923462091-56b420dutr8unacedfgtra74sa020fje.apps.googleusercontent.com";
}else{
    post_url="https://sandbox.account.api.apeaksoft.com/v9/";
    google_client_id="109024478560-p88ct2059545nl4k4bkhacm4tgfq86en.apps.googleusercontent.com";
}
function setCookie(name,value,iDay){      //name相当于键，value相当于值，iDay为要设置的过期时间（天）
    var oDate = new Date();
    oDate.setDate(oDate.getDate() + iDay);
    if(location.hostname.indexOf('.apeaksoft.com')>-1){
        document.cookie = name + '=' + value + ';path=/;domain='+domain+';expires=' + oDate;
    }else{
        document.cookie = name + '=' + value + ';path=/;expires=' + oDate;
    }
}
//记住页面来源
if(getQueryVariable('source')){
    localStorage.setItem('source',getQueryVariable('source'));
}else{
    if(!localStorage.getItem('source')){
        localStorage.setItem('source',websiet);
    }
}
//获取浏览器唯一码
var options = {'excludes': {'userAgent':true,'audio':true,'enumerateDevices':true,'fonts':true,'fontsFlash':true,'canvas':true}}
var murmur=1;
Fingerprint2.get(options, function (components) {
    // 参数
    const values = components.map(function (component) {
        return component.value;
    });
    // 指纹
    murmur = Fingerprint2.x64hash128(values.join(''), 31);
    sessionStorage.setItem('murmur',murmur);
});
// input框的事件
var input_placeholder;
$('.member input').focus(function(){
    input_placeholder=$(this).attr('placeholder');
    $(this).attr('placeholder','');
    $(this).parent().find('p').html('');
    $(this).css({'marginBottom':22});
})
$('.member input').blur(function(){
    $(this).attr('placeholder',input_placeholder);
});
// email框
$('.member input.email').blur(function(){
    let value=$(this).val().replace(/(^\s*)|(\s*$)/g, "");
    $(this).val(value);
    if(!$(this).val()){
        //判断是否邮箱为空
        $(this).addClass('active');
        $(this).next().html(i18n[lang].email_not_valid_1);
        return false;
    }else if(!reg_email.test($(this).val())){
        //正则判断格式是否正确
        $(this).addClass('active');
        $(this).next().html(i18n[lang].email_not_valid);
        return false;
    }else{}
});
$('.member input.email').focus(function(){
    $(this).removeClass('active');
    let value=$(this).val().replace(/(^\s*)|(\s*$)/g, "");
    $(this).val(value);
});
// code
$('.member input.code').blur(function(){
    if($(this).attr('readonly')){
        return false;
    }else{
        if(!$(this).val()){
            $(this).addClass('active');
            $(this).next().next().html(i18n[lang].code_empty);
        }else if(!reg_code.test($(this).val())){
            $(this).addClass('active');
            $(this).next().next().html(i18n[lang].code_not_valid);
        }else{

        }
    }
});
$('.member input.code').focus(function(){
    $(this).removeClass('active');
    if($(this).attr('readonly')){
        $(this).attr('placeholder',input_placeholder);
        $(this).next().next().html(i18n[lang].get_first_code);
    }
});
// password
$('.member input.password').blur(function(){
    if($(this).parents('.login').length>0){
        return false;
    }
    if(!$(this).val()){
        $(this).addClass('active');
        $(this).next().html(i18n[lang].password_empty);
        return false;
    }else if(!reg_password.test($(this).val())){
        $(this).addClass('active');
        $(this).next().html(i18n[lang].password_not_valid_1+'.');
        return false;
    }else{}
});
$('.member input.copy_password').blur(function(){
    if(!$(this).val()){
        $(this).addClass('active');
        $(this).next().html(i18n[lang].copy_password_empty);
        return false;
    }else if(!reg_password.test($(this).val())){
        $(this).addClass('active');
        $(this).next().html(i18n[lang].copy_passwords_inconsistent);
        return false;
    }else if($('.password').val()!=$(this).val()){
        $(this).addClass('active');
        $(this).next().html(i18n[lang].copy_passwords_inconsistent);
        return false;
    }else{}
});
$('.member input.password').focus(function(){
    $(this).removeClass('active');
});
//显示隐藏密码
$('.password_box').find('div').click(function(e){
    e.stopPropagation();
    $(this).parent().toggleClass('active');
    var input=$(this).parent().find('input');
    if($(this).parent().attr('class').indexOf('active')>-1){
        input[0].type="text";
    }else{
        input[0].type="password";
    }
});
//查看提示文字是多少行
(function(){
    // 选择你想要监听的元素
    const targets = document.querySelectorAll('.member .input_box>p,.info .input_box>p');
    // 创建一个观察者对象
    const observer = new MutationObserver(function(mutations, observer) {
        mutations.forEach(function(mutation) {
            if (mutation.type === 'childList') {
                var num=Math.round($(mutation.target).height()/parseFloat($(mutation.target).css('line-height')))
                if(num>1){
                    $(mutation.target).parent().find('input').css({
                        'marginBottom':22*num+6
                    })
                }
            }
        });
    });
    
    // 观察器的配置（观察目标节点的哪些变动）
    const config = { attributes: false, childList: true, subtree: false };
    
    // 对每个目标元素应用观察者
    targets.forEach(target => {
        observer.observe(target, config);
    });
})();
var timeClock;
//获取注册码
function sendCode(element,state){
    if(!state){
        element.html(i18n[lang].Get).removeClass('nobind');
        clearInterval(timeClock);
        return false;
    }
    element.html('60s');
    var timer_num=60;
    timeClock=setInterval(function(){
        timer_num--;
        element.html(timer_num+'s');

        if (timer_num == 0) {
            element.html(i18n[lang].Get).removeClass('nobind');
            element.next().html(i18n[lang].not_received_code);
            clearInterval(timeClock);
        }
    },1000);
};
let page="login"
//回车事件
$(document).keydown(function(event){
    if(event.keyCode==13){
        if(location.href.indexOf('/login')>-1){
            $('.login_btn').click();
            console.log('login');
        }else if(location.href.indexOf('/register')>-1){
            $('.register_btn').click();
            console.log('register');
        }else if(location.href.indexOf('/passwordless-login')>-1){
            $('.passless_login_btn').click();
            console.log('passwordless-login');
        }else if(location.href.indexOf('/connect-account')>-1){
            $('.bind .bind_btn').click();
            console.log('connect-account');
        }else if(location.href.indexOf('/create-password')>-1){
            $('.create_password .create_password_btn').click();
            console.log('create-password');
        }else if(location.href.indexOf('/reset-password')>-1){
            $('.reset .reset_btn').click();
            console.log('reset-password');
        }else{console.log('unknown');}
    }
});
//退出登录
$('.logout').click(function(e){
    e.stopPropagation();
    if(!isnetwork()){return false;};
    let that=$(this);
    $.post(
        post_url+'account/logout',
        {
            e_id:murmur,
            t_id:JSON.parse(getCookie('user')).t_id,
            token:JSON.parse(getCookie('user')).token,
        },
        function(data){
            error_fn(data.error,that);
            switch(data.error){
                case 0:
                    window.location.href="/login";
                    setCookie("user","",-1)
                    break;
                case 20401:
                    error(i18n[lang].error_20401);
                    break;
            }
        }
    ).error(function(xhr){
        error('20499');
    })
});
// tips
$('body').click(function(){
    $('.member .code_box>p>span>span>i').hide();
    $('.info_form').hide();
});
$('.code_box').on('click','.tips',function(e){
    e.stopPropagation();
    $(this).find('i').show()
});
$('.code_box').on('click','.on_feedback',function(){
    $('.feedback').show();
});
$('.code_box').on('click','.close',function(e){
    e.stopPropagation();
    $('.member .code_box>p>span>span>i').hide();
});
// feedback
$('body').append(`<form action="javascript:;" class="feedback clearfloat" method="post" id="feedback">
<i class="feedback_close"></i>
<div class="feedback_container clearfloat">
  <p class="title">
    ${i18n[lang].feedback_title}
  </p>
  <p>${i18n[lang].email}</p>
  <div>
    <input name="email" class="form_email" id="mail" placeholder="${i18n[lang].feedback_email}" />
    <p></p>
  </div>
  <p>${i18n[lang].Contents}</p>
  <div>
    <textarea name="content" id="form_contents" cols="30" rows="10" form="feedback" placeholder="${i18n[lang].feedback_content}"></textarea>
    <p></p>
  </div>
  <input type="submit" value="${i18n[lang].feedback_submit}" id="form_submit" name="submit" />
</div>
</form>
<div class="info_form" style="display: none">
${i18n[lang].feedback_thank_you}
</div>
`);
//feedback提交事件
$('#feedback #mail').focus(function(){
    $(this).next().html('');
});
$('#feedback #mail').blur(function(){
    if($(this).val()==""){
        $(this).next().html(i18n[lang].email_empty);
    }
    if (!reg_email.test($(this).val())) {
        $(this).next().html(i18n[lang].email_not_valid_2);
    }
});
$('#feedback #form_contents').focus(function(){
    $(this).next().html('');
});
$('#feedback #form_contents').blur(function(){
    if ($('#feedback #form_contents').val() == '') {
        $('#feedback #form_contents').next().html(i18n[lang].form_contents);
    }
});
$('#feedback').on('click', '#form_submit', function (e) {
    e.stopPropagation();
    if(!isnetwork()){return false;};
    var mail = $.trim($('#feedback #mail').val());
    if ($('#feedback #mail').val() == '') {
        $('#feedback #mail').next().html(i18n[lang].email_empty);
        return false;
    }
    if (!reg_email.test(mail)) {
        $('#feedback #mail').next().html(i18n[lang].email_not_valid_2);
        return false;
    }
    if ($('#feedback #form_contents').val() == '') {
        $('#feedback #form_contents').next().html(i18n[lang].form_contents);
        return false;
    }
    var data = { f_source: '3', product_id: '70010', email: '', content: '' };
    data['email'] = $('#feedback #mail').val();
    data['content'] = $('#feedback #form_contents').val();
    $('#feedback #mail').val('');
    $('#feedback #form_contents').val('');
    $('.info_form').fadeIn().delay(2000).fadeOut();
    $.ajax({
        url: 'https://feedback.apeaksoft.com/api/feedback/v1/index.php',
        type: 'post',
        data: data,
    });
    $('#feedback').css({ display: 'none' });
});
$('.feedback_close').click(function(e){
    e.stopPropagation();
    $('.feedback').hide();
})
// 登录成功点击完成跳转
$('.member').on('click','.done',function(){
    if(sessionStorage.getItem('operation')=='close'){
        closeTab()
    }else{
        location.href=localStorage.getItem('source');
    }
});
if($('.success .done.time').length>0){
    var timer_num=6;
    timeClock=setInterval(function(){
        timer_num--;
        $('.success .done.time').html(i18n[lang].Done+ '('+timer_num+'s)');
        if (timer_num == 0) {
            if(sessionStorage.getItem('operation')=='close'){
                closeTab()
            }else{
                location.href=localStorage.getItem('source');
            }
            clearInterval(timeClock)
            $('.success .done').html(i18n[lang].Done);
        }
    },1000);
}
function btn_success(element){
    var timer_num=3;
    element.addClass('done');
    element.html(i18n[lang].Done+ '('+timer_num+'s)');
    timeClock=setInterval(function(){
        timer_num--;
        element.html(i18n[lang].Done+ '('+timer_num+'s)');
        if (timer_num == 0) {
            if(sessionStorage.getItem('operation')=='close'){
                closeTab()
            }else{
                location.href=localStorage.getItem('source');
            }
        }
    },1000);
};
// 关闭当前窗口
function closeTab(){
    var browserName = navigator.appName;
    if (browserName=="Netscape") {
        window.location.href="about:blank";
        window.opener='Account'
        window.close();
    }
    else {
        if (browserName == "Microsoft Internet Explorer"){
            window.opener = "Account";
            window.opener = null;
            window.open('', '_top'); 
            window.close();
        }
    } 
    window.opener=null;
    window.open('','_self');
    window.close();
}
//成功自动给登录处理
function autho_success(element,callback){
    if(!isnetwork()){return false;};
    let that=element;
    let email=that.siblings().find('.email').val();
    let password=that.siblings().find('.password').val();
    if(that.attr('class').indexOf('done')>-1){return false;};
    if(!email){
        //判断邮箱是否为空
        that.siblings().find('.email').addClass('active');
        that.siblings().find('.email').next().html(i18n[lang].email_empty);
        return false;
    }else if(!reg_email.test(email)){
        //正则判断格式是否正确
        that.siblings().find('.email').addClass('active');
        that.siblings().find('.email').next().html(i18n[lang].email_not_valid);
        return false;
    }else if(!password){
        //判断秘密是否为空
        that.siblings().find('.password').next().html(i18n[lang].password_empty);
        return false;
    }else if(!reg_password.test(password)){
        //密码格式是否正确
        that.siblings().find('.password').next().html(i18n[lang].password_not_valid);
        return false;
    }else{
        that.find('img').css('opacity',1);
        //发送请求
        $.post(
            post_url+"account/email/login",
            {
                e_id:murmur,
                email:email,
                password:password
            },
            function(data){
                console.log(data);
                that.find('img').css('opacity',0);
                error_fn(data.error,that);
                switch(data.error){
                    case 0:
                        setCookie('user',JSON.stringify({'e_id':murmur,'t_id':data.t_id,'token':data.token}),90);
                        if(callback){
                            callback();
                            return false;
                        }
                        if(getQueryVariable('type')){
                            location.href="/";
                        }else{
                            if(sessionStorage.getItem('operation')=='close'){
                                location.href='/successful-login'
                            }else{
                                location.href=localStorage.getItem('source');
                            }
                        }
                        break;
                    case 20301:
                        that.parents('.login').find('.email').next().html(i18n[lang].error_20301);
                        break;
                    case 20302:
                        that.parents('.login').find('.password').next().html(i18n[lang].error_20302);
                        break;
                    case 20303:
                        that.parents('.login').find('.email').next().html(i18n[lang].error_20303);
                        break;
                    case 20304:
                        that.find('p').html(i18n[lang].error_20304);
                        break;
                    case 20305:
                        that.parents('.login').find('.email').next().html(i18n[lang].error_20305);
                        break;
                    case 20306:
                        that.parents('.login').find('.password').next().html(i18n[lang].error_20306);
                        break;
                    case 20307:
                        that.parents('.login').find('.password').next().html(i18n[lang].password_not_valid);
                        break;
                    case 20308:
                        that.find('p').html(i18n[lang].error_20308);
                        break;
                }
            }
        ).error(function(xhr){
            that.find('img').css('opacity',0);
            overtime(20399);
        });
    };
};
// google登录接口函数
function google_login(callback,registerCallback,mergeCallback){
    $.post(
        post_url+'account/google/login',
        {
            e_id:sessionStorage.getItem('murmur'),
            id_token:JSON.parse(getCookie('user_google')).google_id_token
        },
        function(data){
            console.log(data);
            error_fn(data.error);
            switch(data.error){
                case 0:
                    console.log(murmur);
                    var time=setInterval(function(){
                        if(murmur!=1){
                            clearInterval(time);
                            setCookie('user',JSON.stringify({'e_id':murmur,'t_id':data.t_id,'token':data.token}),90);
                            $.post(
                                post_url+'account/profile',
                                {
                                    e_id:JSON.parse(getCookie('user')).e_id,
                                    t_id:JSON.parse(getCookie('user')).t_id,
                                    token:JSON.parse(getCookie('user')).token
                                },
                                function(data){
                                    if(!error_fn(data.error)){
                                        return false;
                                    };
                                    switch(data.error){
                                        case 0:
                                            if(!data.email){
                                                if(!getCookie('no_email')){
                                                    setCookie('no_email',1,90)
                                                    location.href='/3rd-account-connect'
                                                }else{
                                                    if(callback){
                                                        callback();
                                                        return false;
                                                    }
                                                    if(sessionStorage.getItem('operation')=='close'){
                                                        location.href='/successful-login'
                                                    }else{
                                                        location.href=localStorage.getItem('source');
                                                    }
                                                }
                                            }
                                            break;
                                        case 20901:
                                            error(i18n[lang].error_20901);
                                            break;
                                        case 20902:
                                            error(i18n[lang].error_20902);
                                            break;
                                    }
                                }
                            ).error(function(xhr){
                                $('.body_bg').hide();
                                overtime(20999);
                            });
                        }
                    },1)
                    break;
                case 21601:
                    error(i18n[lang].error_21601);
                    break;
                case 21602:
                    error(i18n[lang].error_21602);
                    break;
                case 21603:
                    error(i18n[lang].error_21603);
                    break;
                case 21604:
                    error(i18n[lang].error_21604);
                    break;
                case 21605:
                    // 不提示，自动去创建账号并登录
                    if(registerCallback){
                        registerCallback()
                        return false;
                    }
                    third_party_registered_fn(0);
                    break;
                case 21606:
                    error(i18n[lang].error_21606);
                    break;
                case 21607:
                    // 不提示，不去注册，跳转是否合并邮箱页面
                    if(mergeCallback){
                        mergeCallback()
                        return false;
                    }
                    // location.href="/3rd-login-merge-account";
                    // 帮忙绑定账号
                    third_party_registered_fn(21607);
                    break;
                case 21608:
                    //自动帮忙注册一个账号
                    if(registerCallback){
                        registerCallback()
                        return false;
                    }
                    third_party_registered_fn(21607);
                    break;
                case 21609:
                    // 不提示，不去注册，跳转是否合并邮箱页面
                    if(mergeCallback){
                        mergeCallback()
                        return false;
                    }
                    // 帮忙绑定账号
                    third_party_registered_fn(21607);
                    break;
                case 21610:
                    // 不提示，自动去注册一个3方账号并登录；创建成功后选择绑定，自动填充获得的Google 邮箱，同21608
                    if(registerCallback&&mergeCallback){
                        registerCallback()
                        mergeCallback()
                        return false;
                    }
                    third_party_registered_fn(0);
                    // 帮忙绑定账号
                    third_party_registered_fn(21607);
                    break;
                case 21611:
                    error(i18n[lang].error_21611);
                    break;
            }
        }
    ).error(function(xhr){
        overtime(21699);
    })
};
// google账号注册
function third_party_registered_fn(operation,callback,callbackGoogle){
    if(!isnetwork()){return false;};
    let data={
        e_id:murmur,
        id_token:JSON.parse(getCookie('user_google')).google_id_token
    };
    console.log(operation);
    switch(operation){
        case 0:
            data={
                e_id:murmur,
                id_token:JSON.parse(getCookie('user_google')).google_id_token
            }
            break;
        case 21607:
            data={
                e_id:murmur,
                id_token:JSON.parse(getCookie('user_google')).google_id_token,
                connect:1
            }
            break;
        case 21608:
            break;
        case 21609:
            break;
        case 21610:
            break;
    }
    $.post(
        post_url+'account/google/register',data,
        function(data){
            console.log(data);
            error_fn(data.error);
            switch(data.error){
                case 0:
                    setCookie('user',JSON.stringify({'e_id':murmur,'t_id':data.t_id,'token':data.token}),90);
                    if(operation==21607){
                        if(callback){
                            callback();
                            return false;
                        }
                    }else{
                        if(callback){
                            callback();
                            return false;
                        }
                    }
                    break;
                case 21801:
                    error(i18n[lang].error_21801);
                    break;
                case 21802:
                    error(i18n[lang].error_21802);
                    break;
                case 21803:
                    error(i18n[lang].error_21803);
                    break;
                case 21804:
                    error(i18n[lang].error_21804);
                    break;
                case 21805:
                    if(callbackGoogle){
                        callbackGoogle()
                        return false;
                    }
                    google_login()
                    break;
                    // 不提示，重新三方登录到新的账号上
                case 21806:
                    error(i18n[lang].error_21806);
                    break;
                case 21807:
                    error(i18n[lang].error_21807);
                    break;
                case 21808:
                    error(i18n[lang].error_21808);
                    break;
                case 21809:
                    error(i18n[lang].error_21809);
                    break;
                case 21810:
                    error(i18n[lang].error_21810);
                    break;
                case 21811:
                    error(i18n[lang].error_21811);
                    break;
                case 21812:
                    error(i18n[lang].error_21812);
                    break;
                case 21813:
                    error(i18n[lang].error_21813);
                    break;
                case 21814:
                    error(i18n[lang].error_21814);
                    break;
                case 21815:
                    error(i18n[lang].error_21815);
                    break;
                case 21816:
                    error(i18n[lang].error_21816);
                    break;
                case 21817:
                    error(i18n[lang].error_21817);
                    break;
                case 21818:
                    error(i18n[lang].error_21818);
                    break;
                case 21819:
                    error(i18n[lang].error_21819);
                    break;
                case 21820:
                    error(i18n[lang].error_21820);
                    break;
                case 21821:
                    error(i18n[lang].error_21821);
                    break;
                case 21822:
                    error(i18n[lang].error_21822);
                    break;
                case 21823:
                    error(i18n[lang].error_21823);
                    break;
                case 21824:
                    error(i18n[lang].error_21824);
                    break;
                case 21825:
                    error(i18n[lang].error_21825);
                    break;
                case 21826:
                    error(i18n[lang].error_21826);
                    break;
                case 21827:
                    error(i18n[lang].error_21827);
                    break;
            }
        }
    ).error(function(xhr){
        overtime(21899);
    })
}
//判断大写锁定是否开启
function isCapsLock(e){
    e = (e) ? e : window.event;

    var charCode = false;
    if (e.which) {
        charCode = e.which;
    } else if (e.keyCode) {
        charCode = e.keyCode;
    }

    var shifton = false;
    if (e.shiftKey) {
        shifton = e.shiftKey;
    } else if (e.modifiers) {
        shifton = !!(e.modifiers & 4);
    }

    if (charCode >= 97 && charCode <= 122 && shifton) {
        return true;
    }

    if (charCode >= 65 && charCode <= 90 && !shifton) {
        return true;
    }

    return false;
}
$(".password").keypress(function(event){
    if(isCapsLock(event)){
        $(this).next().html(i18n[lang].Caps);
    }else{
        $(this).next().html('');
    }
});
//成功
function success(txet){
    $('body').append('<div class="success"><img width="16" height="16" src="https://account.apeaksoft.com/images/success-2.svg" alt="Success">'+txet+'</div>');
    setTimeout(function(){
        $('body>.success').fadeOut();
    },5000);
}
//失败
function error(txet){
    $('body').append('<div class="error"><img width="16" height="16" src="https://account.apeaksoft.com/images/error-2.svg" alt="Error">'+txet+'</div>');
    setTimeout(function(){
        $('body>.error').fadeOut();
    },5000);
}
$(document).click(function(){
    $('body>.success').fadeOut();
    $('body>.error').fadeOut();
});
//提示未发送验证码？
let no_code_text=`<span>${i18n[lang].no_code_text}<i><i class="close"></i>
<span>${i18n[lang].no_code_text_1}</span>
<span>${i18n[lang].no_code_text_2}</span>
<span>${i18n[lang].no_code_text_3}<span class="on_feedback">${i18n[lang].no_code_text_3_span}</span></span>
</i></span></span>`;
// 超时错误
function overtime(num){
    error(i18n[lang].overtime_1+num+i18n[lang].overtime_2);
};
// 判断是否有网
function isnetwork(){
    if(!navigator.onLine){
        error(i18n[lang].isnetwork);
        return false;
    }else{
        return true;
    }
};
var HtmlUtil = {
    /*1.用浏览器内部转换器实现html转码*/
    htmlEncode:function (html){
        //1.首先动态创建一个容器标签元素，如DIV
        var temp = document.createElement ("div");
        //2.然后将要转换的字符串设置为这个元素的innerText(ie支持)或者textContent(火狐，google支持)
        (temp.textContent != undefined ) ? (temp.textContent = html) : (temp.innerText = html);
        //3.最后返回这个元素的innerHTML，即得到经过HTML编码转换的字符串了
        var output = temp.innerHTML;
           temp = null;
           return output;
    },
    /*2.用浏览器内部转换器实现html解码*/
    htmlDecode:function (text){
        //1.首先动态创建一个容器标签元素，如DIV
        var temp = document.createElement("div");
        //2.然后将要转换的字符串设置为这个元素的innerHTML(ie，火狐，google都支持)
        temp.innerHTML = text;
        //3.最后返回这个元素的innerText(ie支持)或者textContent(火狐，google支持)，即得到经过HTML解码的字符串了。
        var output = temp.innerText || temp.textContent;
        temp = null;
        return output;
    }
};
//获取地址栏参数
function getQueryVariable(variable)
{
       var query = window.location.search.substring(1);
       var vars = query.split("&");
       for (var i=0;i<vars.length;i++) {
               var pair = vars[i].split("=");
               if(pair[0] == variable){return pair[1];}
       }
       return(false);
}
//获取cookie
function getCookie(name) {
    var prefix = name + "="
    var start = document.cookie.indexOf(prefix)

    if (start == -1) {
        return null;
    }

    var end = document.cookie.indexOf(";", start + prefix.length)
    if (end == -1) {
        end = document.cookie.length;
    }

    var value = document.cookie.substring(start + prefix.length, end)
    return unescape(value);
}