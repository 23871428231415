(function ($) {
  $.fn.extend({
    device_confirm: function () {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    }
  });
})(jQuery);
(function ($) {
  $.fn.extend({
    system_confirm: function () {
      var userAgentInfo = navigator.userAgent;
      if (userAgentInfo.indexOf("Win") > -1) {
        return "win";
      } else if (userAgentInfo.indexOf("Mac") > -1) {
        return "mac";
      }
    }
  });
})(jQuery);
if (window.innerWidth > 956) {
  $(".tab_index").mouseenter(function () {
    $("#submenu_container").addClass("active");
    $(".submenu")
      .eq($(this).index(".tab_index"))
      .addClass("active")
      .siblings(".submenu")
      .removeClass("active");
  });
  $("#submenu_container").mouseleave(function () {
    $("#submenu_container").removeClass("active");
    $(".submenu").removeClass("active");
  });
}
function system_confirm() {
  var userAgentInfo = navigator.userAgent;
  if (userAgentInfo.indexOf("Win") > -1) {
    return "win";
  } else if (userAgentInfo.indexOf("Mac") > -1) {
    return "mac";
  }
}
function device_confirm() {
  var userAgentInfo = navigator.userAgent;
  var Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod"
  ];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}
(function () {
    var isMac,device_com;
    if(system_confirm()=="mac"){isMac=true}
    else if(system_confirm()=="win"){isMac=false}
    if(device_confirm()==true){device_com=true}
    else if(device_confirm()==false){device_com=false}
    if (isMac && device_com == true) {
        $('.mac_btn_box').css({"display":"flex"});
        $('.mac_btn').css({"display":"block"});
        $('.win_btn_box').css({"display":"none"});
        $('.win_btn').css({"display":"none"});
        $('.mobile_btn').css({"display":"none"});
    } else if (!isMac && device_com == true) {
        $('.win_btn_box').css({"display":"flex"});
        $('.win_btn').css({"display":"block"});
        $('.mac_btn_box').css({"display":"none"});
        $('.mac_btn').css({"display":"none"});
        $('.mobile_btn').css({"display":"none"});
      
    } else if (device_com == false) {
        $('.pc_btn').css({"display":"none"});
        $('.mobile_btn').show();
    };
})();
$(function(){
    $('.product').mouseover(function(){
        $('.product_main').height($('.product_main>ul>li>div').height());
        $('.product_main ul').height($('.product_main>ul>li>div').height());
    });
    $('.product_main>ul>li>span').append('<b></b>');
    $('.product_main>ul>li>span').eq(0).find('i').css('paddingLeft','20px');
    $('.product_main>ul>li>span').eq(0).find('b').css('background','#f2f3f4');
    $('.product_main>ul>li').hover(function(){
        $(this).addClass('active').siblings().removeClass('active');
        $('.product_main>ul>li>span>i').eq($(this).index()).stop().animate({
            paddingLeft:"20px"
        });
        $(this).find('span').find('b').css('background','#f2f3f4');
    },function(){
        $(this).find('span').find('b').css('background','#f7f8f9');
        $('.product_main>ul>li>span>i').eq($(this).index()).stop().animate({
            paddingLeft:"0px"
        })
    });

    $('.product').hover(function(){
        $('.product>img').attr('src','https://www.apeaksoft.com/images/drop-arrow-s.png');
    },function(){
        $('.product>img').attr('src','https://www.apeaksoft.com/images/drop-arrow.png');
    });
    $('.head_down').hover(function(){
        $('.head_down>img').attr('src','https://www.apeaksoft.com/images/drop-arrow-s.png');
    },function(){
        $('.head_down>img').attr('src','https://www.apeaksoft.com/images/drop-arrow.png');
    });
    $(".product_down>ul>li>div div img").before('<i></i>');
    $(".product_down>ul>li>div div.h_down_n i").remove();
    $('.product_down>ul>li>span').eq(0).find('i').css('paddingLeft','20px');
    $('.product_down>ul>li>span').eq(0).find('b').css('background','#f2f3f4');
    $('.product_down>ul>li').hover(function(){
        $(this).addClass('active').siblings().removeClass('active');
        $('.product_down>ul>li>span>i').eq($(this).index()).stop().animate({
            paddingLeft:"20px"
        });
        $(this).find('span').find('b').css('background','#f2f3f4');
    },function(){
        $(this).find('span').find('b').css('background','#f7f8f9');
        $('.product_down>ul>li>span>i').eq($(this).index()).stop().animate({
            paddingLeft:"0px"
        })
    });
    var i=0;
    $('.toggle_nav').click(function(e){
        e.stopPropagation();
        try{
            if($('.login_box ul').css('display')!='none'){
                $('.login_box ul').hide();
            }
        }catch(e){
            console.log(e);
        }
        if($('.nav').css('display')=='none'){
            $('.nav').show();
            $('.nav_bg').show();
        }else{
            $('.nav').hide();
            $('.nav_bg').hide();
        }
        $('.iphone-eraser-nav>.center>ul').hide();
        $('.iphone-eraser-nav>.center>span>i').removeClass('active');
    });
    $('body').append('<div class="nav_bg"></div>');
    $('.nav_bg').click(function(e){
        e.stopPropagation();
        i=0;
        $(this).hide();
        $('.iphone-eraser-nav>.center>span>i').removeClass('active');
        $('.iphone-eraser-nav>.center>ul').hide();
        if($(window).width()<500){
            $('.nav').hide();
            $('.login_box ul').hide();
        }
    });
    //宽度小于550
    if($(window).width()>550){
        $('.header .login_btn .login_box').hover(function(){
            $('.header .login_btn .login_box ul').show();
        },function(){
            $('.header .login_btn .login_box ul').hide();
        });
    }else{
        $('.header .login_box ul').width($(window).width());
        $('.header .login_box').click(function(e){
            e.stopPropagation();
            if($('.nav').css("display")!='none'){
                $('.nav').hide();
            }
            if($('.header .login_box ul').css("display")=='none'){
                 $('.header .login_box ul').show();
                 $('.nav_bg').show();
            }else{
                $('.header .login_box ul').hide();
                $('.nav_bg').hide();
            }
        });
    }
});
console.log("v5.0");